import { Table } from 'reactstrap';
import 'app/assets/scss/custom/components/Table/ReportTable.scss';
import { CSSProperties } from 'react';
import clsx from 'clsx';

export type ReportTableCellType = string | number;

export interface DescriptiveColumn {
  showIndex?: boolean;
  descriptionBodyCellColumnArray: ReportTableCellType[];
  descriptionFootCell?: ReportTableCellType;
  descriptionHeadCell?: ReportTableCellType;
}

export interface ReportTableStyleConfig {
  descriptiveColumnMinWidth?: number;
  cellMinWidth?: number;
  textCenter?: boolean;
  descriptiveColumnTextCenter?: boolean;
}

interface ReportTableInterfaceProps {
  descriptiveColumn?: DescriptiveColumn;
  headRow: ReportTableCellType[];
  bodyRows?: ReportTableCellType[][];
  footRow?: ReportTableCellType[];
  highlightedColumns?: ReportTableCellType[];
  highlightedIndex?: number[];
  tableStyleConfig?: ReportTableStyleConfig;
  className?: string;
  autoFillEmptyCell?: boolean;
}

const ReportTable = ({
  descriptiveColumn,
  headRow,
  bodyRows,
  footRow,
  highlightedColumns,
  highlightedIndex,
  tableStyleConfig = {
    descriptiveColumnMinWidth: 180,
    cellMinWidth: 130,
    textCenter: false,
    descriptiveColumnTextCenter: false,
  },
  className,
  autoFillEmptyCell,
}: ReportTableInterfaceProps) => {
  const cellMinWidthStyle: CSSProperties = {
    minWidth: `${tableStyleConfig.cellMinWidth}px`,
    textAlign: tableStyleConfig.textCenter ? 'center' : 'unset',
  };
  const descriptiveColumnMinWidthStyle: CSSProperties = {
    minWidth: `${tableStyleConfig.descriptiveColumnMinWidth}px`,
    textAlign: tableStyleConfig.descriptiveColumnTextCenter
      ? 'center'
      : 'unset',
  };

  const getIsHighlightedClassName = (
    cell: string | number,
    index: number,
    isCellHead: boolean,
  ) => {
    if (isCellHead) {
      return (highlightedColumns?.length &&
        highlightedColumns.some(highlightedCol => highlightedCol === cell)) ||
        (highlightedIndex?.length &&
          highlightedIndex.some(highlightIndex => highlightIndex === index))
        ? 'highlighted-cell'
        : undefined;
    } else {
      return (highlightedColumns?.length &&
        highlightedColumns.some(
          highlightedCol => headRow[index] && highlightedCol === headRow[index],
        )) ||
        (highlightedIndex?.length &&
          highlightedIndex.some(highlightIndex => highlightIndex === index))
        ? 'highlighted-cell'
        : undefined;
    }
  };

  return (
    <div className={clsx('w-100 overflow-scroll', className)}>
      <Table
        className="report-table w-100"
        // style={{ textAlign: tableStyleConfig.textCenter ? 'center' : 'unset' }}
      >
        <thead className="report-table-head">
          <tr>
            {descriptiveColumn ? (
              descriptiveColumn.descriptionHeadCell ? (
                <td style={descriptiveColumnMinWidthStyle}>
                  {descriptiveColumn.descriptionHeadCell}
                </td>
              ) : (
                <td></td>
              )
            ) : null}
            {headRow.map((headCell, index) => (
              <td
                key={index}
                style={
                  !headCell
                    ? { ...cellMinWidthStyle, width: 50 }
                    : cellMinWidthStyle
                }
                className={getIsHighlightedClassName(headCell, index, true)}
              >
                {headCell}
              </td>
            ))}
          </tr>
        </thead>
        <tbody className="report-table-body">
          {bodyRows?.length
            ? bodyRows.map((bodyRow, index) => (
                <tr key={index}>
                  {descriptiveColumn ? (
                    descriptiveColumn.descriptionBodyCellColumnArray.length &&
                    descriptiveColumn.descriptionBodyCellColumnArray?.[
                      index
                    ] ? (
                      <td style={descriptiveColumnMinWidthStyle}>
                        {descriptiveColumn.showIndex
                          ? `${index + 1}. ` +
                            descriptiveColumn.descriptionBodyCellColumnArray[
                              index
                            ]
                          : descriptiveColumn.descriptionBodyCellColumnArray[
                              index
                            ]}
                      </td>
                    ) : (
                      <td></td>
                    )
                  ) : null}

                  {autoFillEmptyCell
                    ? (() => {
                        const renderedRow: React.ReactNode[] = [];
                        for (let i = 0; i < headRow.length; i++) {
                          if (
                            typeof bodyRow[i] === 'number' ||
                            typeof bodyRow[i] === 'string'
                          ) {
                            renderedRow.push(
                              <td
                                key={i}
                                style={cellMinWidthStyle}
                                className={getIsHighlightedClassName(
                                  bodyRow[i],
                                  i,
                                  false,
                                )}
                              >
                                {bodyRow[i]}
                              </td>,
                            );
                          } else {
                            renderedRow.push(
                              <td
                                key={i}
                                style={cellMinWidthStyle}
                                className={getIsHighlightedClassName(
                                  bodyRow[i],
                                  i,
                                  false,
                                )}
                              >
                                -
                              </td>,
                            );
                          }
                        }
                        return renderedRow;
                      })()
                    : bodyRow.map((bodyCell, index) => (
                        <td
                          key={index}
                          style={cellMinWidthStyle}
                          className={getIsHighlightedClassName(
                            bodyCell,
                            index,
                            false,
                          )}
                        >
                          {bodyCell}
                        </td>
                      ))}
                </tr>
              ))
            : descriptiveColumn?.descriptionBodyCellColumnArray.length
            ? descriptiveColumn.descriptionBodyCellColumnArray.map(
                (descriptiveBodyCell, index) => (
                  <tr key={index}>
                    <td style={descriptiveColumnMinWidthStyle}>
                      {descriptiveColumn.showIndex
                        ? `${index + 1}. ` + descriptiveBodyCell
                        : descriptiveBodyCell}
                    </td>
                  </tr>
                ),
              )
            : null}
        </tbody>
        {footRow?.length || descriptiveColumn?.descriptionFootCell ? (
          <tfoot className="report-table-foot">
            <tr>
              {descriptiveColumn ? (
                descriptiveColumn.descriptionFootCell ? (
                  <td
                    className="foot-description-cell"
                    style={descriptiveColumnMinWidthStyle}
                  >
                    {descriptiveColumn.descriptionFootCell}
                  </td>
                ) : (
                  <td></td>
                )
              ) : null}
              {autoFillEmptyCell
                ? (() => {
                    const renderedRow: React.ReactNode[] = [];
                    for (let i = 0; i < headRow.length; i++) {
                      if (
                        typeof footRow?.[i] === 'number' ||
                        typeof footRow?.[i] === 'string'
                      ) {
                        renderedRow.push(
                          <td
                            key={i}
                            style={cellMinWidthStyle}
                            className={getIsHighlightedClassName(
                              footRow?.[i],
                              i,
                              false,
                            )}
                          >
                            {footRow?.[i]}
                          </td>,
                        );
                      } else {
                        renderedRow.push(
                          <td
                            key={i}
                            style={cellMinWidthStyle}
                            className={getIsHighlightedClassName('-', i, false)}
                          >
                            -
                          </td>,
                        );
                      }
                    }
                    return renderedRow;
                  })()
                : footRow?.map((footCell, index) => (
                    <td
                      key={index}
                      style={cellMinWidthStyle}
                      className={getIsHighlightedClassName(
                        footCell,
                        index,
                        false,
                      )}
                    >
                      {footCell}
                    </td>
                  ))}
            </tr>
          </tfoot>
        ) : null}
      </Table>
    </div>
  );
};

export default ReportTable;
