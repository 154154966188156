import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import 'app/assets/scss/ProfileMenu.scss';
import { CURRENT_ENV, VERSION_NUMBER } from 'app/config';
import { ROUTES } from 'app/constants/Routes';
import { useAppSelector } from 'app/hooks/store';
import { RootState } from 'store';

const ProfileMenu = props => {
  const [menu, setMenu] = useState(false);
  const { profile } = useAppSelector((rootState: RootState) => rootState.auth);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect d-flex align-items-center"
          id="page-header-user-dropdown"
          tag="button"
        >
          <div className="header-user-icon-container">
            <i className="bx bx-user header-user-icon"></i>
          </div>
          <span className="d-none d-xl-inline-block ms-2 me-1">
            {profile?.userName}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link to={ROUTES.USER_PASSWORD} className="dropdown-item">
            <i className="mdi mdi-lock font-size-16 align-middle me-1 text-muted" />
            <span>Update Password</span>
          </Link>
          <Link to={ROUTES.LOGOUT} className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>Logout</span>
          </Link>
          <div className="dropdown-item mt-2">
            <span>{`Ver. ${CURRENT_ENV}-${VERSION_NUMBER}`}</span>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileMenu;
