import { Accessibility, SideBarRoute, UserRole, UserStatus } from 'app/models';
import React, { useEffect, useRef } from 'react';
import SimpleBar from 'simplebar-react';
import { MenuItem } from './MenuItem';
import { ROUTES } from 'app/constants/Routes';
import { useAppSelector } from 'app/hooks/store';
import { RootState } from 'store';
import { useNavigate } from 'react-router-dom';
import { CURRENT_ENV } from 'app/config';

const ROUTES_ARRAY: SideBarRoute[] = [
  {
    path: ROUTES.CALL_CENTER_SEARCH,
    name: 'Call Center',
    regex: '^/call-center/search-all',
    icon: <i className="bx bx-phone-call" />,
    subRoutes: [
      // {
      //   path: ROUTES.CALL_CENTER_SEARCH,
      //   name: 'Search All',
      //   regex: '^/call-center/search-all',
      // },
      {
        path: ROUTES.CALL_CENTER_FREE_CHECK,
        name: 'First Time Free Check',
        regex: '^/call-center/free-check',
      },
      {
        path: ROUTES.CALL_CENTER_APPOINTMENT_REMINDER,
        name: 'Appointment Reminder Follow Up',
        regex: '^/call-center/appointment-reminder',
      },
      {
        path: ROUTES.CALL_CENTER_AFTER_SERVICE,
        name: 'After Service Follow Up',
        regex: '^/call-center/after-service',
      },
      {
        path: ROUTES.CALL_CENTER_SPECIAL,
        name: 'Special Follow Up',
        regex: '^/call-center/special',
      },
      // {
      //   path: ROUTES.CALL_CENTER_EXPORT,
      //   name: 'Export Call Results',
      //   regex: '^/call-center/export',
      // },
      // {
      //   path: ROUTES.CALL_CENTER_STATISTIC,
      //   name: 'Statistic Report',
      //   regex: '^/call-center/statistic',
      // },
    ],
  },
  {
    path: ROUTES.SURVEY_MANAGE,
    name: 'Survey',
    regex: '^/survey',
    icon: <i className="mdi mdi-file-document-edit-outline" />,
    subRoutes: [
      // {
      //   path: ROUTES.SURVEY_SEARCH,
      //   name: 'Search All',
      //   regex: '^/survey/search-all',
      // },
      {
        path: ROUTES.SURVEY_MANAGE,
        name: 'Manage Survey',
        regex: '^/survey/manage',
      },
      {
        path: ROUTES.SURVEY_E_SURVEY,
        name: 'E-Survey',
        regex: '^/survey/e-survey',
      },
      {
        path: ROUTES.SURVEY_PC_HK,
        name: 'PC-Survey (HK)',
        regex: '^/survey/pc-survey-hk',
      },
      {
        path: ROUTES.SURVEY_CV_HK,
        name: 'CV-Survey (HK)',
        regex: '^/survey/cv-survey-hk',
      },
      {
        path: ROUTES.SURVEY_PC_MACAU,
        name: 'PC-Survey (Macau)',
        regex: '^/survey/pc-survey-macau',
      },
      {
        path: ROUTES.SURVEY_CV_MACAU,
        name: 'CV-Survey (Macau)',
        regex: '^/survey/cv-survey-macau',
      },
    ],
  },
  {
    path: '#',
    name: 'Report',
    regex: '^/report/e-report',
    icon: <i className="bx bx-search-alt" />,
    subRoutes: [
      // {
      //   path: ROUTES.REPORT_MFG,
      //   name: 'MFG Report',
      //   regex: '^/report/mfg-report',
      // },
      {
        path: ROUTES.REPORT_E_REPORT,
        name: 'E-Report',
        regex: '^/report/e-report',
      },
      {
        path: ROUTES.REPORT_PC_HK,
        name: 'PC-Report (HK)',
        regex: '^/report/pc-report-hk',
      },
      {
        path: ROUTES.REPORT_CV_HK,
        name: 'CV-Report (HK)',
        regex: '^/report/cv-report-hk',
      },
      {
        path: ROUTES.REPORT_PC_MACAU,
        name: 'PC-Report (Macau)',
        regex: '^/report/pc-report-macau',
      },
      {
        path: ROUTES.REPORT_CV_MACAU,
        name: 'CV-Report (Macau)',
        regex: '^/report/cv-report-macau',
      },
    ],
  },
  {
    path: '#',
    name: 'Brand',
    regex: '^/brand',
    icon: <i className="mdi mdi-car-outline" />,
    subRoutes: [
      {
        path: ROUTES.BRAND_E_BRAND,
        name: 'E-Brand',
        regex: '^/brand/e-brand',
      },
      {
        path: ROUTES.BRAND_PC_HK,
        name: 'PC-Brand (HK)',
        regex: '^/brand/pc-brand-hk',
      },
      {
        path: ROUTES.BRAND_CV_HK,
        name: 'CV-Brand (HK)',
        regex: '^/brand/cv-brand-hk',
      },
      {
        path: ROUTES.BRAND_PC_MACAU,
        name: 'PC-Brand (Macau)',
        regex: '^/brand/pc-brand-macau',
      },
      {
        path: ROUTES.BRAND_CV_MACAU,
        name: 'CV-Brand (Macau)',
        regex: '^/brand/cv-brand-macau',
      },
    ],
  },
  // {
  //   path: ROUTES.OPTIONS,
  //   name: 'Options',
  //   regex: '^/options/e-center-options',
  //   icon: <i className="bx bxs-user-detail" />,
  //   subRoutes: [
  //     {
  //       path: ROUTES.OPTIONS,
  //       name: 'E-Center-Options',
  //       regex: '^/options/e-center-options',
  //     },
  //   ],
  // },
  // {
  //   path: ROUTES.USER,
  //   name: 'User',
  //   icon: <i className="mdi mdi-account-outline" />,
  //   regex: '^/users',
  // },
  {
    path: ROUTES.LOG_LIST,
    name: 'Log',
    icon: <i className="mdi mdi-format-list-bulleted-square" />,
    regex: '^/log',
  },
  {
    path: ROUTES.UPLOAD,
    name: 'Upload',
    icon: <i className="mdi mdi-file-upload-outline" />,
    regex: '^/upload',
  },
  {
    path: ROUTES.USER,
    name: 'Users',
    icon: <i className="mdi mdi-account-cog-outline" />,
    regex: '^/user',
  },
  {
    path: ROUTES.CONFIG,
    name: 'Config',
    icon: <i className="bx bxs-cog" />,
    regex: '^/config',
  },
];

const SidebarContent = props => {
  const ref = useRef<any>();
  const { profile } = useAppSelector((rootState: RootState) => rootState.auth);
  const navigate = useNavigate();
  const routesWithPermission = (originalRouteArray: SideBarRoute[]) => {
    let routesArray: SideBarRoute[] = [...originalRouteArray];

    if (
      !profile ||
      profile.role === UserRole.warehouse ||
      profile.status === UserStatus.已停用
    ) {
      navigate('/login');
      return [];
    } else {
      if (profile.role === UserRole.admin) {
        if (!(CURRENT_ENV === 'Dev' || CURRENT_ENV === 'UAT')) {
          return originalRouteArray.filter(
            route =>
              route.path !== ROUTES.CALL_CENTER_SEARCH ||
              route.name !== 'Call Center',
          );
        }
        return originalRouteArray;
      } else {
        routesArray = [
          ...routesArray.filter(
            route => route.path !== ROUTES.USER || route.name !== 'Users',
          ),
        ];
        routesArray = [
          ...routesArray.filter(
            route => route.path !== ROUTES.CONFIG || route.name !== 'Config',
          ),
        ];
        routesArray = [
          ...routesArray.filter(
            route => route.path !== ROUTES.LOG_LIST || route.name !== 'Log',
          ),
        ];
        routesArray = [
          ...routesArray.filter(
            route => route.path !== ROUTES.UPLOAD || route.name !== 'Upload',
          ),
        ];
        if (!profile.UserPermission?.brands.length) {
          routesArray = [
            ...routesArray.filter(
              route =>
                route.path !== ROUTES.BRAND_E_BRAND || route.name !== 'Brand',
            ),
          ];
        }
        if (
          !profile.UserPermission?.accessibility.includes(Accessibility.Call)
        ) {
          routesArray = [
            ...routesArray.filter(
              route =>
                route.path !== ROUTES.CALL_CENTER_SEARCH ||
                route.name !== 'Call Center',
            ),
          ];
        }
        if (
          !profile.UserPermission?.accessibility.includes(Accessibility.Survey)
        ) {
          routesArray = [
            ...routesArray.filter(
              route =>
                route.path !== ROUTES.SURVEY_MANAGE || route.name !== 'Survey',
            ),
          ];
        }
      }

      if (!(CURRENT_ENV === 'Dev' || CURRENT_ENV === 'UAT')) {
        return routesArray.filter(
          route =>
            route.path !== ROUTES.CALL_CENTER_SEARCH ||
            route.name !== 'Call Center',
        );
      }
      return routesArray;
    }
  };

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.recalculate();
    }
  });

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul style={{ listStyle: 'none', padding: 0 }}>
            {routesWithPermission(ROUTES_ARRAY).map((route, index) => (
              <MenuItem route={route} key={index} />
            ))}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

export default SidebarContent;
